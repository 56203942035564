<template>
  <v-app id="inspire" class="accent">
    <v-container fluid>
      <v-row class="mx-auto mt-5 pb-5">
        <v-col cols="12">
          <h1>
            <router-link :to="{ name: 'AssessmentCenter' }"
             >Assessment center</router-link
            >
            | <span class="title">Vehicle Reinspection </span>
           </h1>
         </v-col>
       </v-row>
       <v-container>
         <v-row class="mt-10">
           <v-col class="mx-auto" cols="12" xl="11">
            <reinspection-form />
           </v-col>
         </v-row>
       </v-container>
     </v-container>
   </v-app>
 </template>

 <script>
import bookingsMixin from '@/mixins/bookingsMixin';
export default {
    name: '',
  mixins: [bookingsMixin],
  components: {
    reinspectionForm: () =>
      import(
        '@/components/assessment-center/reinspection/ReinspectionForm'
      )
    },
}
 </script>

 